@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'breakpoints';
@import 'reset-style';

@layer base {
  html {
    font-family: 'Libre Bodoni', serif;
    scroll-behavior: smooth;
    @apply text-hl-black;
    @apply text-base;
  }

  body {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }
}

@layer components {
  .overlay {
    @apply fixed;
    @apply bg-hl-black-20;
    @apply w-screen;
    @apply h-screen;
    @apply inset-0;
    @apply z-50;
    &[aria-hidden='true'] {
      @apply hidden;
    }
  }
}

// custom scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  @apply bg-hl-black-20;
}

::-webkit-scrollbar-thumb {
  @apply bg-hl-primary;
  @apply rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-hl-primary-20;
}

.coming-soon {
  &__cover {
    &-container {
      max-width: 600px;
      width: 100%;
    }

    &-wrapper {
      width: 100%;
      padding-bottom: calc(380 / 600 * 100%);
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
  }
}

// button
.button {
  &-container {
    &:before {
      content: '';
      width: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}

// nav-link
.nav-link__container {
  .active {
    @apply text-hl-primary;
  }
}
