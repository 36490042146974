.loading-mask {
  &__container {
    @apply fixed;
    @apply w-screen;
    @apply h-screen;
    @apply inset-0;
    @apply bg-hl-black-20;
    @apply z-[100];
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply m-0 #{!important};
  }

  &__item {
    width: 40px;
    height: 40px;
    @apply text-hl-primary;
    background: linear-gradient(currentColor 0 0), linear-gradient(currentColor 0 0), linear-gradient(currentColor 0 0),
      linear-gradient(currentColor 0 0);
    background-size: 21px 21px;
    background-repeat: no-repeat;
    animation: loadingItemAnimation 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
}

@keyframes loadingItemAnimation {
  0% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }
  33% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    width: 60px;
    height: 60px;
  }
  66% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    width: 60px;
    height: 60px;
  }
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
