.Toastify__toast {
  @apply font-hl-mulish;
  @apply flex;
  @apply p-4;
  @apply text-base;
  @apply rounded-lg;

  &--success {
    @apply text-blue-700 bg-blue-100;
    .Toastify__toast-icon > svg {
      @apply fill-blue-700;
    }
  }

  &--error {
    @apply text-red-700 bg-red-100;
    .Toastify__toast-icon > svg {
      @apply fill-red-700;
    }
  }
}
