@import 'src/assets/scss/index';

.header {
  &-container {
    transition: background-color 0.2s ease-in-out;

    &__sticky {
      animation-name: headerScrollDown;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      position: sticky;
    }
  }

  &-sticky__menu {
    @media (max-width: 1023px) {
      position: absolute;
      height: fit-content;
      width: 100%;
      transition: all 0.2s ease-in-out;

      &-active {
        left: 0 !important;
      }
    }
  }

  &-button {
    span {
      width: 24px;
      height: 2px;
      transition: all 0.2s ease-in-out;
      border-radius: 10px;
      @apply bg-hl-black;
    }

    &[data-header-button='true'] {
      span {
        &:nth-child(1) {
          position: absolute;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          transform: translateX(100%);
        }

        &:nth-child(3) {
          position: absolute;
          margin-top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@keyframes headerScrollDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
